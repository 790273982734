import axios from "axios";
import getCookie from "./components/cookies/getCookies";

/* console.log(getCookie("mytocken"));
 */
//const BASE_URL = "http://localhost:5000/api/";
const BASE_URL = "https://kbdapi.onrender.com/api/";
/* 
let TOKEN = "";

if (
  JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
    .currentUser !== null
) {
  TOKEN = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
    .currentUser.aceesTocken;
  console.log("admin");
} else {
  console.log("Not admin");
} */
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${getCookie("mytocken")}` },
});

export const baseUrlForMedia = BASE_URL;
